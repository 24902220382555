

<div class="custom-table-container cust-table mat-elevation-z8">
  <mat-toolbar class="toolbar">
    <mat-form-field  class="search"  >
      <input matInput #filter placeholder="Search">
    </mat-form-field>
  </mat-toolbar>
  <div class="add-new-btn" >
    Add New
    <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">add_circle_outline</mat-icon>
  </div>
  <table class="table">
    <thead>
    <tr >
      <th  *ngFor="let item of headers ">{{item}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of data" >
      <td *ngFor="let column of headers">
        <i *ngIf="column =='actions'" class="material-icons">edit</i>
        <i *ngIf="column =='actions'" class="material-icons">delete</i>
        <i class="material-icons" *ngIf="column =='default'">check_box</i>
        <span *ngIf="(column != 'actions') && (column != 'default')"> {{item[column]}} </span>
      </td>
    </tr>
    </tbody>
    <tfoot>
    <tr >
      Footer Content goes here

    </tr>
    </tfoot>
  </table>
</div>

<!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

<!-- ID Column -->

