import { ChangeDetectorRef, Component } from '@angular/core';
import { SpinnerService } from '../../_services/spinner.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'custom-spinner',
  templateUrl: './custom.spinner.component.html',
  styleUrls: ['./custom.spinner.component.scss']
})
export class CustomSpinnerComponent {
  public spinnerState$: Observable<boolean>;
  public spinnerTitle = 'Loading ...';

  constructor(
    private readonly spinnerService: SpinnerService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.spinnerState$ = this.spinnerService.spinnerState$.pipe(
      tap(() => setTimeout(() => this.cdr.detectChanges())),
    );
  }
}
