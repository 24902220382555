import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-vs-table',
  templateUrl: './vs-table.component.html',
  styleUrls: ['./vs-table.component.css']
})
export class VsTableComponent implements OnInit {
  @Input() data;
  headers= [];
  constructor() {
  }

  ngOnInit() {
    this.headers = this.getHeaders(this.data);
     }

  getHeaders(data) {
    return Object.keys(data[0]);
  }
}



