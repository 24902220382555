import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, FeatureFlagService } from 'app/_services';
import { environment } from '../../environments/environment';
import { RestrictionsComponent } from 'app/shared/abstractions';

@Component({
  selector: 'app-root',
  templateUrl: './default_layout.component.html',
  styleUrls: ['./default_layout.component.scss']
})
export class DefaultLayoutComponent extends RestrictionsComponent implements OnInit {
  searchForm: UntypedFormGroup;
  current_user: object;
  spinning: boolean = false;
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    authenticationService: AuthenticationService,
    featureFlagService: FeatureFlagService,
  ) {
    super();
    this.searchForm = this.fb.group({ query: ['', Validators.required] });
  }

  @ViewChild('throbber') throbber: ElementRef;
  ngOnInit() {
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
  }

  isTesting() : boolean {
    return environment.api_host.toString().match(/janus.prod\-techstreet\.com/) == null;
  }

  vulcanNameDisplay() : string {
    if(this.isTesting()){
      return 'Vulcan - Testing';
    }else{
      return 'Vulcan - Production';
    }
  }

  search() {
    this.router.navigate(['/search', this.searchForm.value.query]);
  }



}
