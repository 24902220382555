import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RoutingModule} from './routing/routing.module';
import {SharedModule} from './shared/shared.module';
import {StyleguideModule} from './styleguide/styleguide.module';

import {AppComponent} from './app.component';
import {AuthenticationService} from './_services/authentication.service';
import {HomeComponent} from './home/home.component';
import {DefaultLayoutComponent} from './layouts/default_layout.component';
import {LoginLayoutComponent} from './layouts/login_layout.component';
import {PrintableLayoutComponent} from './layouts/printable-layout.component';
import {ShoppingSummaryComponent} from './layouts/shopping-summary/shopping-summary.component';
import {AppDataService} from './_services/app_data.service';
import {AddressValidationService} from './_services/address_validation.service';
import {OrderService} from './_services/order.service';
import {ShoppingService} from './_services/shopping.service';
import {PricingService} from './_services/pricing.service';
import {JWT_OPTIONS, JwtHelperService, JwtModule} from '@auth0/angular-jwt';
import {SpinnerInterceptor} from './_services/spinner_interceptor';
import {UnauthRecoveryInterceptor} from './_interceptors/unauth-recovery.interceptor';
import {environment} from '../environments/environment';
import {CustomSpinnerComponent} from './layouts/spinner/custom.spinner.component';
import {SpinnerService} from './_services/spinner.service';
import { StoreModule } from './store/store.module';
import { NeptuneTokenInterceptor } from './_interceptors/neptune-token.interceptor';
import { FeatureFlagService } from './_services';

// Services for initial data
export function appDataServiceFactory(appDataService: AppDataService): Function {
  return () => appDataService.load();
}

export function featureFlagServiceFactory(featureFlagService: FeatureFlagService): Function {
  return () => featureFlagService.initialize();
}

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem('token');
    },
    allowedDomains: [
      environment.api_host.toString().replace('https://', '').replace('http://', ''),
      environment.neptune_api.toString().replace('https://', '').replace('http://', ''),
    ]
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DefaultLayoutComponent,
    LoginLayoutComponent,
    ShoppingSummaryComponent,
    PrintableLayoutComponent,
    CustomSpinnerComponent,
  ],
  exports: [
    SharedModule
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RoutingModule,
    SharedModule,
    StyleguideModule,
    JwtModule.forRoot({
      config: {
        allowedDomains: ['localhost:4200', 'janus.dev-innovation.com', 'janus.staging-techstreet.com'],
        authScheme: 'JWT '
      },
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      }
    }),
    StoreModule,
  ],
  providers: [
    AuthenticationService,
    AppDataService,
    FeatureFlagService,
    AddressValidationService,
    { provide: APP_INITIALIZER, useFactory: appDataServiceFactory, deps: [AppDataService], multi: true },
    { provide: APP_INITIALIZER, useFactory: featureFlagServiceFactory, deps: [FeatureFlagService], multi: true },
    OrderService,
    HttpClient,
    ShoppingService,
    PricingService,
    JwtHelperService,
    SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true, deps: [SpinnerService] },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthRecoveryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NeptuneTokenInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
}
