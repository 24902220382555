import { Component, OnInit } from '@angular/core';
import {Versions} from "../../environments/versions";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  versions = Versions
  constructor() { }

  ngOnInit() {
    console.log('SHA'+' = ' +this.versions.revision);
    console.log('Branch'+' = ' +this.versions.branch);
  }

}
