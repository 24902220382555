/* src/app/styleguide/styleguide.component.css */
mat-sidenav {
  width: 200px;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.24);
}
nav h3 {
  border: medium none;
  font-size: 12px;
  margin: 0;
  padding: 0 16px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.32) none repeat scroll 0 0;
  color: rgba(255, 255, 255, 0.87);
}
nav ul li > a {
  box-sizing: border-box;
  display: block;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  color: #673ab7;
  text-align: left;
}
pre {
  background: rgba(0, 0, 0, 0.01) none repeat scroll 0 0;
  border: 0.5px solid rgba(0, 0, 0, 0.03);
  white-space: nowrap;
  padding: 10px 0;
  display: block;
}
#address,
#single {
  display: inline-block;
  vertical-align: top;
  margin-right: 5%;
}
.view-code {
  white-space: pre-line;
}
/*# sourceMappingURL=styleguide.component.css.map */
