
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable()
export class UnauthRecoveryInterceptor implements HttpInterceptor {

  constructor(private _injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(catchError(res => {
      if (res instanceof HttpErrorResponse) {
        if(res.status === 401) {
          const router: Router = this._injector.get(Router);
          const state: RouterStateSnapshot = router.routerState.snapshot;
          router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url }});
        }
        return observableThrowError(res);
      }
    }));

  }
}
