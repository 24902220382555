/* src/app/layouts/default_layout.component.scss */
.vulcan.throb {
  -webkit-animation: shake 0.1s ease-in-out 0.1s infinite alternate;
}
mat-list-item:nth-child(n+2):hover {
  background: transparent;
}
.bold {
  font-weight: 700;
}
@-webkit-keyframes shake {
  from {
    -webkit-transform: rotate(10deg);
  }
  to {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-10deg);
  }
}
/*# sourceMappingURL=default_layout.component.css.map */
