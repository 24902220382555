<mat-toolbar color="primary" xmlns="http://www.w3.org/1999/html">
<h1>Vulcan Style Guide</h1>
</mat-toolbar>
<mat-sidenav-container class="example-container">
  <mat-sidenav class="example-sidenav" opened="true" disableClose="true" mat-disable-backdrop="true" mode="side">
    <nav>
      <h3>Layout</h3>
      <ul>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Page</a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Grid System</a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Cards</a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Tables</a>
        </li>
      </ul>
    </nav>
    <nav>
      <h3>Typography</h3>
      <ul>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Heading</a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Body Copy</a>
        </li>
      </ul>
    </nav>
    <nav>
      <h3>Misc. Components</h3>
      <ul>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Hyperlinks</a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Toggle</a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Checkbox</a>
        </li>
      </ul>
    </nav>
    <nav>
      <h3>Tables</h3>
      <ul>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Basic </a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Table Component</a>
        </li>
        <li>
          <a mat-raised-button><mat-icon>code</mat-icon>Custom </a>
        </li>
      </ul>
    </nav>
  </mat-sidenav>

  <div class="sidenav-content">
    <h2>Style Guide Elements</h2>

    <section>
      <h3>Page Layout</h3>
      <mat-list>
        <mat-list-item>
          1. Every component (mat-table excluded) page should contain Grid System. Examples of Grid System and how to use it is below.
        </mat-list-item>
        <mat-list-item>
          <pre>
            &lt;mat-grid-list cols="2" rowHeight="150px" gutterSize="10px"&gt;<br/>
              &lt;mat-grid-tile&gt;1&lt;/mat-grid-tile&gt;<br/>
              &lt;mat-grid-tile&gt;2&lt;/mat-grid-tile&gt;<br/>
            &lt;/mat-grid-list&gt;
          </pre>
        </mat-list-item>
      </mat-list>
    </section>
<section>
  <h3>Page Layout - mat Table View</h3>
  <p>View is available in Account Management -> Credit Cards. Below code can be modified for any columns or rows needed.<br>
    Below is a specific example for Credit Card screen.</p>
<pre class="view-code">
    1. To insert single table on page start by inserting code between below
    <pre>&lt;div class="page single-table"&gt; &nbsp; &lt;/div&gt;</pre>
    2. Every Screen will contain a H2 title
    <pre>&lt;h2&gt;Credit Cards&lt;/h2&gt;</pre>
    3. To insert Material Design Table, use the below div to insert table within. The class associated in the div will take care of the styling and elevation of component on the page.
  <pre>&lt;div class="mat-table-custom mat-elevation-z8"&gt;mat Table Code goes in between &lt;/div&gt;</pre>
    4. Every Data Table should have a mat-Toolbar (toolbar above the table) to allow place for any text or action buttons.
      a) Within the toolbar, the left side of the toolbar will be for search (not included in this example).
      b) Within the toolbar, the right side of the toolbar will be for action button (add new in this example).
      c) Action Text or Button w/ mat Icon does not need any styling as long as the mark up is used correctly.
      d) Using span class spacer , will allow you to fill up space without having to apply any specific styling.
    <pre>
        &lt;mat-toolbar&gt;<br>
    &lt;span class="spacer"&gt;&lt;/span&gt;<br>
    &lt;a (click)="createCreditCard()"&gt;Add New&lt;mat-icon&gt;add_circle_outline&lt;/mat-icon&gt;&lt;/a&gt;<br>
  &lt;/mat-toolbar&gt;
    </pre>
    5. Following the mat-Toolbar above, inserting mat table comes next. Mentioning any specific styling or class is not necessary as material design is pre-styled.
    <pre>&lt;mat-table #table [dataSource]="dataSource"&gt;</pre>
  </pre>
</section>
    <section>
    <h3>Grid System</h3>
      <h4>Example 1 - 2 Column Page Layout with Card & Form</h4>
      <pre>
        1) Row Heights can be tweaked to any height required.<br/>
        2) Gutter size can be changed using px or em<br/>
        3) Usage of Grid List will make it simple to layout components
      </pre>
      <mat-grid-list cols="2" rowHeight="200px" gutterSize="10px">
        <mat-grid-tile>
          <mat-card>
            <mat-card-title>Standard Card</mat-card-title>
            <mat-card-subtitle>Subtitle</mat-card-subtitle>
            <mat-card-content>
              <p>Standard card with content Lorem Ipsum Data</p>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions>
              <button mat-button color="primary">Action</button>
              <button mat-button>Cancel</button>
            </mat-card-actions>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
          <form class="example-form">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Company (disabled)" disabled value="Vulcan">
            </mat-form-field>
            <p>
              <mat-form-field class="example-full-width">
                <input matInput placeholder="First Name" value="Clarivate Analytics">
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <textarea matInput placeholder="Address"></textarea>
              </mat-form-field>
            </p>
          </form>

        </mat-grid-tile>
      </mat-grid-list>
      <pre>
        &lt;mat-grid-list cols="2" rowHeight="200px" gutterSize="10px"&gt;<br/>
          &lt;mat-grid-tile&gt;<br/>
        Content goes here<br/>
          &lt;/mat-grid-tile&gt;<br/>
        &lt;/mat-grid-list&gt;<br/>
      </pre>

      <!--- Example 2 -->
      <h4>Example 2 - Multi Column/Row Grid Layout</h4>
      <mat-grid-list cols="4" rowHeight="100px">
        <mat-grid-tile
          *ngFor="let tile of tiles"
          [colspan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="tile.color">
          {{tile.text}}
        </mat-grid-tile>
      </mat-grid-list>
      <pre>
        &lt;mat-grid-list cols="4" rowHeight="100px"&gt;<br/>
        &lt;mat-grid-tile<br/>
          *ngFor="let tile of tiles"<br/>
          [colspan]="tile.cols"<br/>
          [rowspan]="tile.rows"<br/>
          [style.background]="tile.color"&gt;<br/>
        &lt;/mat-grid-tile&gt;<br/>
      &lt;/mat-grid-list&gt;<br/><br/>
        <div>TS File</div>
  tiles = [<br/>
    &#123;text: 'One', cols: 2, rows: 1, color: 'purple'&#125;,<br/>
    &#123;text: 'Two', cols: 2, rows: 2, color: 'green'&#125;,<br/>
    &#123;text: 'Three', cols: 2, rows: 1, color: 'gray'&#125;,<br/>
    &#123;text: 'Four', cols: 4, rows: 1, color: '#DDBDF1'&#125;,<br/>
  ];
      </pre>
    </section>

    <section>
      <h3>Cards</h3>
      <h4>Example 1 - Address Idea</h4>
      <p>Two cards below with 25% width & 50% width</p>
      <mat-card class="mat-quarter-width display-inline-block">
        <mat-card-title><mat-icon class="vertical-align-bottom">location_on</mat-icon>Default Address</mat-card-title>
        <mat-divider></mat-divider>
        <mat-list>
          <mat-list-item>
            <h5 mat-line>John Jameson</h5>
            <p mat-line>123 Test Dr, Troy, MI, 48309</p>
          </mat-list-item>
        </mat-list>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button mat-button color="primary" class="text-upper">Edit</button>
          <button mat-button color="warn" class="text-upper">Delete</button>
        </mat-card-actions>
      </mat-card>

      <mat-card class="mat-quarter-width display-inline-block">

        <mat-list>
          <mat-list-item>
            <h4 mat-line>John Jameson</h4>
            <p mat-line>123 Test Dr, Troy, MI, 48309</p>
          </mat-list-item>
        </mat-list>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button mat-button color="primary" class="text-upper">Edit</button>
          <button mat-button color="warn" class="text-upper">Delete</button>
        </mat-card-actions>
      </mat-card>

      <h4>Example 2 - Email & Phone Card Idea</h4>
      <mat-card class="mat-quarter-width display-inline-block">
        <mat-card-title><mat-icon class="vertical-align-bottom">phone</mat-icon> Default Number</mat-card-title>
        <mat-divider></mat-divider>
        <mat-list>
          <mat-list-item>
            <h4 mat-line>248-855-9008</h4>
          </mat-list-item>
        </mat-list>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button mat-button color="primary" class="text-upper">Edit</button>
          <button mat-button color="warn" class="text-upper">Delete</button>
        </mat-card-actions>
      </mat-card>
    </section>

    <section>
      <h3>Forms</h3>
      <h4>Example 1 - 2 Column Format</h4>
      <pre>
        1) Each Form should have a headline - H4<br/>
      </pre>
      <form id="address" class="two-column">
        <h4>Address Form</h4>
        <mat-radio-group>
          Default:
          <mat-radio-button value="1" color="primary">Option 1</mat-radio-button>
          <mat-radio-button value="2">Option 2</mat-radio-button>
        </mat-radio-group>
        <mat-form-field>
        <input matInput placeholder="First Name" value="">
      </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Last Name" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Company" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Address 1" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Address 2" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Address 3" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="First Name" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Last Name" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="City" value="">
        </mat-form-field>
        <mat-form-field class="zip_code">
          <input matInput placeholder="Zip Code" value="">
        </mat-form-field>
        <mat-form-field class="zip_code_ext">
        <input matInput placeholder="Zip Code Ext" value="">
      </mat-form-field>
        <mat-select placeholder="State">
          <mat-option>Michigan</mat-option>
        </mat-select>
        <mat-select placeholder="Country">
          <mat-option>USA</mat-option>
        </mat-select>
        <button class="form_btn" mat-raised-button color="accent">Cancel</button>
        <button class="form_btn" mat-raised-button color="primary">Save</button>
      </form>
      <form id="single" class="single-column">
        <h4>Credit Card Form</h4>
        <mat-checkbox color="primary">
          Default Checkbox
        </mat-checkbox>
        <mat-form-field>
          <input matInput placeholder="Name" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Credit Card Number" value="">
        </mat-form-field>
        <mat-select placeholder="Month">
          <mat-option>08</mat-option>
        </mat-select>
        <mat-select placeholder="Year">
          <mat-option>1990</mat-option>
        </mat-select>
        <mat-form-field>
          <input matInput placeholder="CVC" value="">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Placeholder" value="">
        </mat-form-field>
        <button class="form_btn" mat-raised-button color="accent">Cancel</button>
        <button class="form_btn" mat-raised-button color="primary">Save</button>
      </form>
    </section>
    <section>
      <h3>Heading</h3>
      <div class="padding-top-10">h1 - <h1 class="display-inline">Title- Vulcan</h1></div>
      <div class="padding-top-10">h2 - <h2 class="display-inline">Title- Vulcan</h2></div>
      <div class="padding-top-10">h3 - <h3 class="display-inline">Title- Vulcan</h3></div>
      <div class="padding-top-10">h4 - <h4 class="display-inline">Title- Vulcan</h4></div>
      <div class="padding-top-10">h5 - <h5 class="display-inline">Title- Vulcan</h5></div>
      <div class="padding-top-10">h6 - <h6 class="display-inline">Title- Vulcan</h6></div>
      <h3>Body Copy</h3>
    </section>
    <section class="toggle">
    <h3>Toggle</h3>
      <mat-slide-toggle color="primary">Primary</mat-slide-toggle>
      <mat-slide-toggle color="secondary">Secondary</mat-slide-toggle>
      <mat-slide-toggle color="warn">Warning</mat-slide-toggle>
    <pre>
            &lt;mat-slide-toggle color="primary"&gt;Primary&lt;/mat-slide-toggle&gt;<br/>
            &lt;mat-slide-toggle color="secondary"&gt;Secondary&lt;/mat-slide-toggle&gt;<br/>
            &lt;mat-slide-toggle color="warn"&gt;Warning&lt;/mat-slide-toggle&gt;
    </pre>
    </section>

    <section class="checkbox">
      <h3>Checkbox</h3>
      <mat-checkbox color="primary">Primary Checkbox</mat-checkbox>
      <mat-checkbox color="warn">Warning Checkbox</mat-checkbox>
      <pre>
        &lt;mat-checkbox color="primary"&gt;Primary Checkbox&lt;/mat-checkbox&gt;<br/>
      &lt;mat-checkbox color="warn"&gt;Warning Checkbox&lt;/mat-checkbox&gt;
    </pre>
    </section>

    <!--table html-->

    <section class="matTable" >
      <h2 id="basicTableWithPagination" class="h2 sec">Basic Table with Pagination</h2>
      <h2>Credit Cards</h2>
      <div class="example-container cust-table mat-elevation-z8">
        <mat-toolbar class="toolbar">

          <mat-form-field class="search" floatPlaceholder="never">
            <input matInput #filter placeholder="Search">
          </mat-form-field>
        </mat-toolbar>

        <div class="add-new-btn" >
          Add New <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">add_circle_outline</mat-icon>
        </div>

        <mat-table #table [dataSource]="dataSource">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- ID Column -->
          <ng-container cdkColumnDef="userId">
            <mat-header-cell *cdkHeaderCellDef> ID </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.id}} </mat-cell>
          </ng-container>

          <!-- Progress Column -->
          <ng-container cdkColumnDef="progress">
            <mat-header-cell *cdkHeaderCellDef> Progress </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.progress}}% </mat-cell>
          </ng-container>

          <!-- Default Column -->
          <ng-container cdkColumnDef="default">
            <mat-header-cell *cdkHeaderCellDef> Default </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <i class="material-icons">check_circle</i>
            </mat-cell>
          </ng-container>


          <!-- Color Column -->
          <ng-container cdkColumnDef="color">
            <mat-header-cell *cdkHeaderCellDef>Color</mat-header-cell>
            <mat-cell *cdkCellDef="let row" [style.color]="row.color"> {{row.color}} </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container cdkColumnDef="userName">
            <mat-header-cell *cdkHeaderCellDef> Name </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.name}} </mat-cell>
          </ng-container>

          <!-- Action Column -->
          <ng-container cdkColumnDef="action">
            <mat-header-cell *cdkHeaderCellDef> Action </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <i class="material-icons">edit</i>
              <i class="material-icons">delete</i>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator #paginator [length]="exampleDatabase.data.length" [pageIndex]="0" [pageSize]="3" [pageSizeOptions]="[3, 4, 5]">
        </mat-paginator>

      </div>
    </section>


    <section>
      <h2 class="sec">Custom Table Component</h2>
      <h2>Credit Cards</h2>

      <app-vs-table [data]="sampleData2"></app-vs-table>

      <br/>
      <b>How to use Component table</b>
      <pre class="view-code"><code>
    1. Pass the data,  in array format like  data=[
    {{ '{' }}name:'Pradeep',age:24,Nationality:'India'{{ '}' }},
    {{ '{' }}name:'Nidhi',age:24,Nationality:'India’{{ '}' }},] in your ts file as property of component.

    2. Use the tag app-vs-table
<br/>
    <b>Unique Features:</b>
    1. We can pass any json collection with any number of collections
    2.It will automatic adjust height and width

    app-vs-table.   -  Tag to render table <br/>
    [data]=data.    -  data needs to be passed
  </code></pre>
      <br/>

    </section>




    <section class="custom-table-section">
      <h2 id="" class="sec">Custom Table</h2>
      <h2>Credit Cards</h2>
      <div class="custom-table-container cust-table mat-elevation-z8">
        <mat-toolbar class="toolbar" >

          <mat-form-field class="search" >
            <input matInput #filter placeholder="Search">
          </mat-form-field>
        </mat-toolbar>
        <div class="add-new-btn" >
          Add New
          <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">add_circle_outline</mat-icon>
        </div>
        <table class="table">
          <thead>
          <tr >
            <th *ngFor="let column of columnsForCustomTable"><span [innerHTML]="column"></span></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of sampleData" >
            <td *ngFor="let column of columnsForCustomTable">
              <i *ngIf="column =='actions'" class="material-icons">edit</i>
              <i *ngIf="column =='actions'" class="material-icons">delete</i>
              <i class="material-icons" *ngIf="column =='default'">check_box</i>
              <span *ngIf="(column != 'actions') && (column != 'default')"> {{row[column]}} </span>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr >
            Footer Content goes here
          </tr>
          </tfoot>
        </table>
      </div>
      <br/>
      <br/>
      How to make  use of Custom Table
      1. Copy the html file for example :
      <pre class="view-code"> <code>

      &lt;div class="custom-table-container cust-table mat-elevation-z8"&gt;
  &lt;mat-toolbar class="toolbar"&gt;

    &lt;mat-form-field class="search" &gt;
      &lt;input matInput #filter placeholder="Search"&gt;
    &lt;/mat-form-field&gt;
  &lt;/mat-toolbar&gt;
  &lt;div class="add-new-btn"&gt;
    Add New
    &lt;mat-icon class="mat-icon material-icons" role="img" aria-hidden="true"&gt;add_circle_outline&lt;/mat-icon&gt;
  &lt;/div&gt;
  &lt;table class="table"&gt;
    &lt;thead&gt;
    &lt;tr &gt;
      &lt;th *ngFor="let column of columnsForCustomTable"&gt;&lt;span [innerHTML]="column"&gt;&lt;/span&gt;&lt;/th&gt;
    &lt;/tr&gt;
    &lt;/thead&gt;
    &lt;tbody&gt;
    &lt;tr *ngFor="let row of sampleData" &gt;
      &lt;td *ngFor="let column of columnsForCustomTable"&gt;
        &lt;i *ngIf="column =='actions'" class="material-icons"&gt;edit&lt;/i&gt;
        &lt;i *ngIf="column =='actions'" class="material-icons"&gt;delete&lt;/i&gt;
        &lt;i class="material-icons" *ngIf="column =='default'"&gt;check_box&lt;/i&gt;
        &lt;span *ngIf="(column != 'actions') && (column != 'default')" [innerHTML]="row[column]"&gt;  &lt;/span&gt;
      &lt;/td&gt;
        &lt;/tr&gt;
    &lt;/tbody&gt;
    &lt;tfoot&gt;
    &lt;tr &gt;
      &lt;th *ngFor="let column of columnsForCustomTable" [innerHTML]="column" &gt;

      &lt;/th&gt;
    &lt;/tr&gt;
    &lt;/tfoot&gt;
  &lt;/table&gt;
&lt;/div&gt;
    </code>
</pre>
      2. Declare two properties in component where you want to make use of this table( one for header like here we have taken as   columnForCustomTable and other for data as sampleData). You can use other variable names as well just rename it wherever you find it after *ngFor keyword.

      <br/>
      <br/>
      sampleData.   —  Contains collection of data in array format <br/>
      columnForCustomTable - Collection of strings as column header info
      <br/>





    </section>


  </div>

</mat-sidenav-container>







