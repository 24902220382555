import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { StyleguideComponent } from './styleguide.component';
import { VsTableComponent } from './vs-table/vs-table.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [VsTableComponent, StyleguideComponent]
})
export class StyleguideModule { }
