import { Component, OnInit } from '@angular/core';
import { ShoppingService } from '../../_services/shopping.service';
import { LineItem } from '../../_models/line_item';
import {Order} from "../../_models/order";

@Component({
  selector: 'app-shopping-summary',
  templateUrl: './shopping-summary.component.html',
  styleUrls: ['./shopping-summary.component.css']
})
export class ShoppingSummaryComponent implements OnInit {
  items: LineItem[] = [];
  nullOrder: number = 1;
  public orderModel = new Order();
  constructor(public shoppingService: ShoppingService) { }

  ngOnInit() {
    this.shoppingService.items().subscribe(
      success => this.items = success
    );    
  }

  get gateway() {
    return this.shoppingService.gateway;
  }

  get membership() {
    return this.shoppingService.membership;
  }

  shopping_currency() {
    return this.shoppingService.currency;
  }

  total() {
    return this.shoppingService.total;
  }

  currency_exchange () {
    if (this.shoppingService.order !== null) {
      return this.shoppingService.order.exchange_rate;
    }
    else {
      return this.nullOrder;

    }
  }


}
