<mat-toolbar [ngClass]="{'navBar':true, 'testing': isTesting()}">
  <span class="userInfo"><button color="primary" routerLink="/authentication/logout" mat-mini-fab>
    <mat-icon color="">account_circle</mat-icon></button></span>
  <span #throbber class="vulcan">{{ vulcanNameDisplay() }}</span>
<span class="spacer"></span>
  <form [formGroup]="searchForm" (ngSubmit)="search()" novalidate name="search">
    <mat-form-field color="accent">
      <input matInput formControlName="query">
    </mat-form-field>
    <button color="primary" mat-mini-fab><mat-icon>search</mat-icon></button>
  </form>
  <span class="fill" style="text-align: right">
    <app-shopping-summary></app-shopping-summary>
  </span>
</mat-toolbar>

<custom-spinner></custom-spinner>

<mat-sidenav-container>
  <mat-sidenav #sidenav
              class="mainNavigation"
              mode='side'
              opened="true">
    <mat-nav-list>
      <mat-list-item routerLink="/home" matTooltip="Home" Position="right">
        <a><mat-icon mat-list-icon>home</mat-icon></a>
      </mat-list-item>
      <ng-container *ngIf="!isReadonly">
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/accounts">
          <a matTooltip="Account" Position="right"><mat-icon mat-list-icon>account_box</mat-icon></a>
        </mat-list-item>
      </ng-container>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/orders-on-hold" matTooltip="Orders On Hold" Position="right">
        <a id="menu-orders-on-hold"><mat-icon mat-list-icon>price_check</mat-icon></a>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/vendors" matTooltip="Vendors" Position="right">
        <a id="menu-vendor"><mat-icon mat-list-icon>auto_awesome_motion</mat-icon></a>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/products" matTooltip="Products" Position="right">
        <a id="menu-products"><mat-icon mat-list-icon>library_books</mat-icon></a>
      </mat-list-item>
      <ng-container *ngIf="!isReadonly">
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/coupons" matTooltip="Coupons" Position="right">
          <a id="menu-coupons"><mat-icon mat-list-icon class="bold">%</mat-icon></a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/gateways" matTooltip="Gateways" Position="right">
          <a id="menu-gateways"><mat-icon mat-list-icon>arrow_circle_up</mat-icon></a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/discounts" matTooltip="Discounts">
          <a id="menu-discounts"><mat-icon mat-list-icon>monetization_on</mat-icon></a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/procurement" matTooltip="Procurement" Position="right">
          <a id="menu-procurement"><mat-icon mat-list-icon>local_shipping</mat-icon></a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/subgroups" matTooltip="Subgroups" Position="right">
          <a id="menu-subgroups"><mat-icon mat-list-icon>folder_open</mat-icon></a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/sso-issues" matTooltip="SSO Troubleshooting" Position="right">
          <a id="menu-sso-issues"><mat-icon mat-list-icon>admin_panel_settings</mat-icon></a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/pdf-issues/errors" matTooltip="PDF Issues" Position="right">
          <a id="pdf-issues"><mat-icon mat-list-icon>playlist_remove</mat-icon></a>
        </mat-list-item>
      </ng-container>
      <mat-divider></mat-divider>
      <mat-list-item>
        <a><mat-icon color="accent" mat-list-icon>settings</mat-icon></a>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-nav-list>
  </mat-sidenav>
  <div class="content" id="mainContent">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
